import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  socialWrapper: {
    flexDirection: 'column'
  },

  socialTitle: {
    marginBottom: theme.spacing( 4 ),
    '& span': {
      color: theme.palette.text.main,
      fontSize: theme.typography.body2.fontSize,
      [theme.breakpoints.up( 'sm' )]: {
        fontSize: theme.typography.body1.fontSize
      }
    }
  },

  socialButtons: {
    justifyContent: 'center',
    alignItems: 'center'
  },

  socialButton: {
    margin: theme.spacing( 1 ),
    '& span': {
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.primary.main,
      [theme.breakpoints.up( 'sm' )]: {
        fontSize: theme.typography.h4.fontSize
      }
    }
  }
}) )
