export const openModal = ( dispatch ) => async (
    title,
    component
) => {
  dispatch( { type: 'set_children', payload: { component, title } } )
  dispatch( { type: 'open' } )
}

export const closeModal = ( dispatch ) => async () => {
  dispatch( { type: 'close' } )
}

