import { makeStyles } from '@material-ui/core'
import { MAX_WIDTH } from '../Layout/Layout'


export default makeStyles( ( theme ) => ({
  section: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: ( { dark } ) =>
        dark
        ? theme.palette.extras.oxfordBlueAlt
        : theme.palette.background.paper
  },

  sectionContainer: {
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minHeight: ( { fullHeight } ) =>
        fullHeight && 'calc(100vh - 64px)',
    padding: theme.spacing( 4 ),
    position: 'relative',
    zIndex: 100,
    [theme.breakpoints.up( 'md' )]: {
      padding: theme.spacing( 6 ),
      maxWidth: MAX_WIDTH
    }
  },

  bg: {
    position: 'absolute',
    left: 0,
    top: 0,
    filter: ( { blured } ) => (blured ? 'blur(20px)' : 'none'),
    height: '100%',
    width: '100%'
  }
}) )
