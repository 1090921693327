import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  root: {
    padding: theme.spacing( 2, 0 ),
    flexDirection: 'column',
    justifyContent: 'center'
  },
  description: {
    marginBottom: ( { regularBottom } ) =>
        !regularBottom && theme.spacing( 4 ),
    lineHeight: theme.lineHeight( 2 ),
    color: ( { light } ) => light && theme.palette.text.lightText,
    [theme.breakpoints.up( 'sm' )]: {
      fontSize: theme.typography.body1.fontSize
    },
    [theme.breakpoints.up( 'md' )]: {}
  }
}) )
