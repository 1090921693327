import createDataContext from '../createDataContext'
import modalReducer from './reducer'

import * as Main from './methods/main'

export const { Provider, Context } = createDataContext(
    modalReducer,
    {
      openModal: Main.openModal,
      closeModal: Main.closeModal
    },
    { hidden: true, component: null, title: '' }
)
