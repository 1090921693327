import { Grid } from '@material-ui/core'
import * as React from 'react'
import useStyles from './styles'


const SectionContainer = ( {
                             children,
                             fullHeight,
                             sectionID,
                             dark,
                             blured,
                           } ) => {
  const { sectionContainer, section } = useStyles( {
    fullHeight,
    blured,
    dark,
  } )

  return (
      <section id={ sectionID } className={ section }>
        <Grid className={ sectionContainer }>{ children }</Grid>
      </section>
  )
}

export default SectionContainer
