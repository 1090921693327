import * as React from 'react'
import { useContext } from 'react'

import { Modal } from '../_common'
import { Context as ModalContext } from '../../context/ModalContext'


const ModalController = () => {
  const {
    state: { hidden, component, title }, closeModal
  } = useContext( ModalContext )

  return (<Modal title={ title } open={ !hidden } onClose={ closeModal }>
        { component }
      </Modal>)
}

export default ModalController
