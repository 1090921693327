import { createTheme } from '@material-ui/core/styles'

const theme = createTheme( {
  palette: {
    primary: {
      main: '#5BC0BE',
      mainLight: 'rgba(91, 192, 190, 0.30)'
    },

    secondary: {
      main: '#FFC49B'
    },

    text: {
      // TEXT
      primary: '#294C60',
      lightText: 'white',
      hint: '#65AFFF',
      disabled: 'rgba(51, 51, 51, 0.50)'
    },

    background: {
      // BACKGROUND
      default: 'white',
      paper: 'white',
      paperLight: '#F3F3F3',
      darkBackground: '#001B2E'
    },

    overlays: {
      // OVERLAYS
      darkOverlay: 'rgba(0,0,0,0.75)',
      overlay: 'rgba(0,0,0,0.50)',
      lightOverlay: 'rgba(0,0,0,0.20)',
      whiteOverlay: 'rgba(255,255,255,0.35)',
      gradientOverlay: 'linear-gradient(170deg, rgba(91, 192, 190, 0.2) 0%, rgba(255,255,255,0.0) 50%)'
    },

    status: {
      // STATUS
      danger: '#db4848',
      success: '#009966',
      warning: '#F0D398'
    },

    extras: {
      // EXTRAS
      none: 'transparent',
      oxfordBlue: '#0B132B',
      spaceCadet: '#1C2541',
      independence: '#F0BE74',
      oxfordBlueAlt: '#001B2E',
      charcoal: '#294C60',
      cadetBlueCrayola: '#ADB6C4',
      papayaWhip: '#FFEFD3',
      peachCrayola: '#FFC49B',

      transitions: {
        default: 'all 0.3s ease-in-out',
        slowEaseInOut: 'all 1.2s ease-in-out',
        fastEaseInOut: 'all 0.3s ease-in-out'
      }
    }
  }, // TYPOGRAPHY
  typography: {
    fontFamily: [ '"Lato"', 'Open Sans' ].join( ',' ),
    titleFontFamily: [ '"Fira Sans"', 'Open Sans' ].join( ',' ),
  }, // UTILITIES
  roundness: 32,
  roundnessSmall: 4,
  hairlineWidth: 1,
  letterspacing: num => `${ num }px`,
  lineHeight: num => `${ num }rem`,
  boxShadowShort: '0 10px 30px rgba(0, 0 ,0, .1)',
  boxShadow: `
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12)`,
} )

export default theme