import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'

  },
  title: {
    margin: theme.spacing( 1, 4 ),

    width: '100%',

    fontFamily: theme.typography.titleFontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    textAlign: ( { centered } ) => (centered ? 'center' : 'left'),

    color: ( { dark } ) =>
        dark
        ? theme.palette.text.lightText
        : theme.palette.text.primary,

    [theme.breakpoints.up( 'sm' )]: {
      fontSize: theme.typography.h3.fontSize
    }
  }
}) )
