import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import useStyles from './styles'
import { Grid, Typography } from '@material-ui/core'
import { Button } from '../index'


const Hero = () => {
  const styles = useStyles()
  const alt = 'Rancho Neminatura'

  return (
      <div className={ styles.heroRoot }>

        <div className={ styles.heroMainContentContainer }>
          <Grid item className={ styles.heroText }>
            <Typography variant="h4">Rancho</Typography>
            <Typography variant="h1">Neminatura</Typography>
            <Typography component="span">Farmer - Citlali Gomez from Global Aquaculture Alliance</Typography>
            <Grid className={styles.ctaWrapper}>
              <Button
                  small
                  aria={ 'Conocer más' }
                  goTo={ '/nosotros' }
              >
                Conocer más
              </Button>
            </Grid>
          </Grid>
        </div>

        <div className={ styles.heroImageContainer }>
          <StaticImage
              src="../../../images/bgnemi3.jpg"
              className={ styles.img }
              layout="fullWidth"
              placeholder="blurred"
              quality={100}
              { ...{ alt } } />
          <Grid container className={ styles.heroContainer }/>

        </div>
      </div>
  )
}

export default Hero
