import { ThemeProvider } from '@material-ui/styles'
import * as React from 'react'
import { Footer, Navbar } from '..'

import { Seo } from '../../_common'
import useStyles from './styles'

import "../../../global.css"

import theme from '../../../App.theme'
import { Provider as ModalContextProvider } from '../../../context/ModalContext'
import { ModalController } from '../../_controllers'
import { CssBaseline } from '@material-ui/core'

import '../../_common/Button/buttonLoader.css'


if ( typeof window !== 'undefined' ) {
  require( 'smooth-scroll' )( 'a[href*="#"]' )
}

export const HEIGHT = 64
export const MAX_WIDTH = 1440

const Layout = ( {
                   children, withNav, seoTitle = '', seoDescription = ''
                 } ) => {
  const styles = useStyles( { withNav, HEIGHT } )

  // const { showScroll, scrollToTop } = usePageScroll()

  return (<>
    <Seo title={ seoTitle } description={ seoDescription }/>
    <ThemeProvider { ...{ theme } }>

      <ModalContextProvider>
        <CssBaseline/>
        <ModalController/>

        <Navbar { ...{ withNav } } />

        <main className={ styles.main }>
          { children }

          {/*{ showScroll && (<Button*/ }
          {/*    aria-label="Ir hasta arriba"*/ }
          {/*    onClick={ scrollToTop }*/ }
          {/*    className={ styles.button }*/ }
          {/*>*/ }
          {/*  Arriba*/ }
          {/*</Button>) }*/ }
        </main>

        <Footer/>
      </ModalContextProvider>

    </ThemeProvider>
  </>)
}

export default React.memo( Layout )
