import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  buttonWrapper: {
    margin: theme.spacing( 2, 0 ),
    justifyContent: 'center',
    alignItems: 'center'
  },

  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing( 1 )
  }
}) )
