import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share'

import { MdEmail } from 'react-icons/md'
import { FaFacebook, FaLinkedin } from 'react-icons/fa'
import { AiFillTwitterCircle } from 'react-icons/ai'
import { IoLogoWhatsapp } from 'react-icons/io'

import useStyles from './styles'


const SocialShareIcons = ( { text, shareTitle, url } ) => {
  const {
    socialButtons,
    socialButton,
    socialWrapper,
    socialTitle
  } = useStyles()

  return (
      <Grid container className={ socialWrapper }>
        <Grid item className={ socialTitle }>
          <Typography component="span">{ text }</Typography>
        </Grid>
        <Grid container item className={ socialButtons }>
          <FacebookShareButton
              quote={ shareTitle }
              className={ socialButton }
              url={ url }
          >
          <span>
            <FaFacebook/>
          </span>
          </FacebookShareButton>
          <TwitterShareButton
              title={ shareTitle }
              className={ socialButton }
              url={ url }
          >
          <span>
            <AiFillTwitterCircle/>
          </span>
          </TwitterShareButton>
          <WhatsappShareButton
              title={ shareTitle }
              className={ socialButton }
              url={ url }
          >
          <span>
            <IoLogoWhatsapp/>
          </span>
          </WhatsappShareButton>
          <LinkedinShareButton
              title={ shareTitle }
              source={ url }
              className={ socialButton }
              url={ url }
          >
          <span>
            <FaLinkedin/>
          </span>
          </LinkedinShareButton>
          <EmailShareButton
              subject={ shareTitle }
              className={ socialButton }
              url={ url }
          >
          <span>
            <MdEmail/>
          </span>
          </EmailShareButton>
        </Grid>
      </Grid>
  )
}

export default SocialShareIcons
