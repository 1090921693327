import { Divider, Grid, Typography } from '@material-ui/core'
import * as React from 'react'

import useStyles from './styles'


const SectionDivider = ( { title, color } ) => {
  const { sdRoot, sdTitleContainer, sdTitle, sdDivider } = useStyles( { color } )
  return (
      <Grid container className={ sdRoot }>
        {
          title && <Grid item xs className={ sdTitleContainer }>
            <Typography className={ sdTitle } noWrap>
              { title }
            </Typography>
          </Grid>
        }
        <Grid item xs>
          <Divider className={ sdDivider }/>
        </Grid>
      </Grid>
  )
}

export default SectionDivider
