import { AppBar, Backdrop, Grid, IconButton, Slide, useScrollTrigger } from '@material-ui/core'
import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'
import { CgMenuMotion } from 'react-icons/cg'
import { animated, useSpring } from 'react-spring'

import { navbarLinks } from '../../../constants/navlinks'
import { usePath, useWindowOnTop } from '../../../hooks'
import { Logo } from '../../_common'
import { HEIGHT } from '../Layout/Layout'
import useStyles from './styles'
import { Link } from 'gatsby'


const HideOnScroll = props => {
  const { children, window } = props

  const trigger = useScrollTrigger( {
    target: typeof window !== 'undefined' ? window() : undefined
  } )
  return (
      <Fragment>
        { props.withNav ? (
            <Fragment>{ children }</Fragment>
        ) : (
              <Slide appear={ false } direction="down" in={ !trigger }>
                { children }
              </Slide>
          ) }
      </Fragment>
  )
}

const Navbar = props => {
  const [ isOpen, setNav ] = useState( false )

  const { isWindowOnTop } = useWindowOnTop( { withNav: props.withNav } )
  const [ path ] = usePath()

  const styles = useStyles( { isWindowOnTop, isOpen, HEIGHT } )

  useEffect( () => {
    if ( isOpen ) {
      document.getElementsByTagName( 'body' )[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName( 'body' )[0].style.overflow = 'scroll'
    }
  }, [ isOpen ] )

  const toggleNav = () => {
    setNav( isOpen => !isOpen )
  }

  const fadeOutProps = useSpring( {
    opacity: isOpen ? 1 : 0
  } )
  const fadeInProps = useSpring( { opacity: isOpen ? 0 : 1 } )

  return (
      <Fragment>
        <HideOnScroll { ...props }>
          <AppBar classes={ { root: styles.appBar } }>
            <Grid container className={ styles.navbar }>
              <Grid container item xs className={ styles.menuContainer }>
                <Grid container item xs justifyContent="flex-end">

                  <Grid container item xs={ 4 } className={ styles.logoBtnContainer }>
                    <IconButton
                        onClick={ toggleNav }
                        className={ styles.logoBtn }
                        aria-label="Menú"
                    >
                      { !isOpen && <animated.div style={ fadeInProps }>
                        <CgMenuMotion/>
                      </animated.div> }
                    </IconButton>
                  </Grid>


                  <Grid container item xs={ 4 } className={ styles.shown }>
                    <Link to="/">
                      <Logo width={ 100 }/>
                    </Link>
                  </Grid>

                  <Grid item xs={ 4 }/>

                </Grid>

                <animated.div
                    style={ fadeOutProps }
                    className={
                      isOpen
                      ? styles.animWrapper
                      : `${ styles.animWrapper } ${ styles.hidden }`
                    }
                >
                  <Grid className={ styles.mdLogoContainer }><Logo width={ 100 }/></Grid>
                  <ul
                      className={
                        isOpen
                        ? styles.navLinks
                        : `${ styles.navLinks } ${ styles.hidden }`
                      }
                  >
                    { navbarLinks.map( ( e, i ) => (
                        <li
                            key={ i }
                            className={ path === e.path ? styles.selectedNavLink : '' }
                        >
                          <Link
                              to={ e.path }
                              onClick={ toggleNav }
                          >
                            { e.text }
                          </Link>
                        </li>
                    ) ) }

                    <li className={ styles.CTA }>
                      <Link to={ '/contacto' }>
                        Contacto
                      </Link>
                    </li>
                  </ul>

                </animated.div>
                <Backdrop
                    className={ styles.backdrop }
                    open={ isOpen }
                    onClick={ toggleNav }
                />
              </Grid>
            </Grid>
          </AppBar>
        </HideOnScroll>
      </Fragment>
  )
}

export default React.memo( Navbar )
