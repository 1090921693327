import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

import useStyles from './styles'


const Logo = ({ width }) => {
  const { img } = useStyles({ width })
  const alt = 'Logo Neminatura'

  return (
      <StaticImage
          src="../../../images/thumbnail.png"
          placeholder="blurred"
          layout="fixed"
          className={img}
          height={80}

          {...{ alt }} />
  )
}

export default Logo
