import { makeStyles } from '@material-ui/core'
import { HEIGHT } from './Layout'


export default makeStyles( ( theme ) => ({
  main: {
    position: 'relative',
    marginTop: 0,

    [theme.breakpoints.up( 'md' )]: {
      marginTop: 0
    }
  },

  button: {
    padding: theme.spacing( 0.12 ),
    backgroundColor: '#FFEFD3',
    fontSize: theme.typography.body2.fontSize,
    textTransform: 'none',
    position: 'fixed',
    top: HEIGHT + 10,
    left: '50%',

    width: '7rem',
    marginLeft: '-3.5rem',

    zIndex: 7000,
    opacity: 1,

    boxShadow: theme.boxShadow,

    '& span': {
      color: 'black', fontWeight: 'bold', fontSize: theme.typography.body2.fontSize
    },

    '&:hover': {
      backgroundColor: '#F0BE74'
    },

    [theme.breakpoints.up( 'md' )]: {
      top: HEIGHT * 2 + 10
    }
  }
}) )
