import { useState, useCallback } from 'react'


const useLoadingButton = () => {
  const [ isButtonLoading, setButtonLoading ] = useState( false )

  const setButtonLoader = useCallback( ( bool ) => {
    setButtonLoading( bool )
  }, [] )

  return [ isButtonLoading, setButtonLoader ]
}

export default useLoadingButton
