import * as React from 'react'
import { FaEnvelope, FaFacebook, FaInstagram, FaPhone } from 'react-icons/fa'

// todo <a href="https://www.freepik.com/vectors/background">Background vector created by createvil -
// www.freepik.com</a> todo Photo by <a
// href="https://unsplash.com/@johnwernerphotography?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">John
// Werner</a> on <a
// href="https://unsplash.com/s/photos/trout?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
// todo Photo by <a
// href="https://unsplash.com/@rafaelalbornoz?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">rafael
// albornoz</a> on <a
// href="https://unsplash.com/s/photos/sustainability?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>

export const TELEPHONE = '527151041387'

const footerLinks = [
  {
    path: '/contacto', text: 'Contacto', external: false
  }
  // {
  //   path: '/legal', text: 'Aviso de privacidad', external: false
  // }
]

const footerNavigationLinks = [
  {
    text: 'Neminatura', items: [
      {
        path: '/', text: 'Inicio'
      },
      {
        path: '/nosotros', text: 'Nosotros'
      },
      {
        path: '/nosotros', text: 'Clientes', sectionID: 'Clientes'
      },
      {
        path: '/nosotros', text: 'Alianzas', sectionID: 'Alianzas'
      },
      {
        path: '/nosotros', text: 'Galería', sectionID: 'galeria'
      }

    ]
  },
  {
    text: 'Productos', items: [
      {
        path: '/productos', text: 'Productos Neminatura'
      },
      {
        path: '/productos', text: 'Certificaciones', sectionID: 'certificaciones'
      }
    ]
  },

  {
    text: 'Comunidad', items: [
      {
        path: '/experiencias', text: 'Experiencias'
      },
      {
        path: '/blog', text: 'Blog'
      }
    ]
  }
]

const navbarLinks = [
  {
    path: '/', text: 'Inicio'
  },
  {
    path: '/nosotros', text: 'Nosotros'
  },
  {
    path: '/productos', text: 'Productos'
  },
  {
    path: '/experiencias', text: 'Experiencias'
  },
  {
    path: '/blog', text: 'Blog'
  }
]

const socialLinks = [
  {
    title: 'Facebook', icon: <FaFacebook/>, url: 'https://www.facebook.com/Neminatura/'
  },
  {
    title: 'Instagram', icon: <FaInstagram/>, url: 'https://www.instagram.com/neminaturasustentable/'
  },
  {
    title: 'Correo', icon: <FaEnvelope/>, url: 'mailto:neminatura@gmail.com?subject=Contacto sitio web'
  }
]

export { footerLinks, socialLinks, navbarLinks, footerNavigationLinks }
