import { makeStyles } from '@material-ui/core'
import { MAX_WIDTH } from '../../_layout/Layout/Layout'


export default makeStyles( ( theme ) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    maxHeight: '90vh',
    maxWidth: MAX_WIDTH,

    margin: 'auto auto',
    outline: ' none'
  }, paper: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    borderRadius: theme.roundness,
    minHeight: '50vh', // minWidth: "50vw",

    maxHeight: '100%'
  }, card: {
    borderRadius: theme.roundness, minHeight: '100%', minWidth: '100%',

    backgroundColor: theme.palette.background.paperLight
  }, action: {
    margin: theme.spacing( 0 )
  }, header: {
    padding: theme.spacing( 2 )
  }, cardContent: {
    // height: `calc(100% - 60px)`,
    padding: theme.spacing( 2 ), overflowY: 'scroll', overFlowX: 'hidden', msOverflowX: 'hidden'
  }, titleClass: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary
  },

  closeButton: {
    color: theme.palette.text.primary
  }
}) )
