import { makeStyles } from '@material-ui/core'
import { MAX_WIDTH } from '../Layout/Layout'


export default makeStyles( ( theme ) => ({
  root: {
    paddingTop: theme.spacing( 4 ),
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden'
  },

  backgroundRoot: {
    position: 'absolute',
    top: 0,
    left: '-10%',
    width: '125%',
    height: '100%',
    transform: 'skewY(-8deg) rotate(2deg) skewY(4deg) translateY(25px)',
    backgroundColor: theme.palette.primary.main
  },

  decorationTriangle: {
    position: 'absolute',
    top: 0,
    right: '10%',
    width: '190px',
    height: '35px',
    background: '#fff',
    transform: 'translateY(-30px) rotate(5deg)'
  },

  footer: {
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing( 4 ),

    [theme.breakpoints.up( 'md' )]: {
      maxWidth: MAX_WIDTH
    }
  },

  links: {
    position: 'relative',
    zIndex: 10,
    padding: theme.spacing( 2, 1 ),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    transition: theme.palette.extras.transitions.default,
    '& a': {
      padding: theme.spacing( 1 ),
      margin: theme.spacing( 0 ),
      color: theme.palette.text.primary,
      transition: theme.palette.extras.transitions.default,
      textDecoration: 'none',
      textTransform: 'uppercase',
      fontSize: theme.typography.body2.fontSize,
      fontFamily: theme.typography.titleFontFamily,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.up( 'sm' )]: {
        padding: theme.spacing( 0, 2 ),
        borderRight: `1px solid ${ theme.palette.text.disabled }`
      }
    },
    [theme.breakpoints.up( 'sm' )]: {
      flexDirection: 'row',
      '& :hover': {
        fontWeight: theme.typography.fontWeightBold
      },
      '& a:last-child': {
        borderRight: 'none'
      }
    }
  },
  icons: {
    '& button': {
      display: 'inline-block',
      margin: theme.spacing( 2 ),
      fontSize: theme.typography.h3.fontSize,
      color: theme.palette.text.primary,
      transition: theme.palette.extras.transitions.default
    },
    [theme.breakpoints.up( 'md' )]: {
      '& button': {
        margin: theme.spacing( 4, 2 )
      },
      '& :hover': {
        color: theme.palette.secondary.main
      }
    }
  },
  address: {
    margin: theme.spacing( 2, 0 ),
    position: 'relative',
    zIndex: 10,
    letterSpacing: theme.letterspacing( 0.75 ),
    lineHeight: 2,
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize
  },

  list: {
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    flexDirection: 'column',

    [theme.breakpoints.up( 'md' )]: {
      margin: theme.spacing( 4, 0, 8, 0 ),
      flexDirection: 'row'
    }
  },

  subheader: {
    padding: theme.spacing( 0 ),
    lineHeight: theme.spacing( 0.20 )
  },

  sectionTitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    fontFamily: theme.typography.titleFontFamily
  },

  footerNavItem: {
    padding: theme.spacing( 0 ),
    margin: theme.spacing( 0 )
  },

  footerNavItemLink: {
    margin: theme.spacing( 0.25, 0 ),
    textDecoration: 'none',
    color: theme.palette.text.primary,
    opacity: 0.95,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.spacing( 0.20 ),
    cursor: 'pointer'
  },

  footerNavSection: {
    margin: theme.spacing( 2 ),
    flexGrow: 1,
    '& ul': {
      padding: theme.spacing( 0 )
    }
  },

  developedBy: {
    color: theme.palette.text.primary,
    opacity: 0.75
  },

  countryContainer: {
    position: 'relative',
    zIndex: 10,
    padding: theme.spacing( 6, 0 ),
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up( 'md' )]: {
      width: '100%'
    }
  },

  countryIcon: {
    fontSize: theme.typography.h2.fontSize,
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },

  countryText: {
    alignItems: 'center',
    padding: theme.spacing( 0, 2 ),
    '& span': {
      color: theme.palette.text.primary,
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightBold
    }

  }

}) )
