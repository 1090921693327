import * as React from "react"
import {  useReducer } from "react"


const createDataContext = (
  reducer,
  actions,
  defaultValue
) => {
  const Context = React.createContext(null)
  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue)
    const boundActions = {}

    for (const key in actions) {
      // noinspection JSUnfilteredForInLoop
      boundActions[key] = actions[key](dispatch, state)
    }

    return (
      <Context.Provider value={ { state, ...boundActions } }>
        { children }
      </Context.Provider>
    )
  }

  return { Context, Provider }
}

export default createDataContext
