import { Box, Grid, IconButton, List, ListItem, ListSubheader, Typography } from '@material-ui/core'
import * as React from 'react'

import { footerLinks, footerNavigationLinks, socialLinks } from '../../../constants/navlinks'
import useStyles from './styles'
import { Link, navigate } from 'gatsby'
import { GiMexico } from 'react-icons/gi'


const Footer = () => {
  const styles = useStyles()

  const internalLinks = footerLinks.filter( e => !e.external )
  const externalLinks = footerLinks.filter( e => e.external )

  return (
      <section id="footer" className={ styles.root }>
        <Grid className={ styles.backgroundRoot }>
          <Grid className={ styles.decorationTriangle }/>
        </Grid>
        <Box className={ styles.footer }>
          <Grid container className={ styles.countryContainer }>
            <Grid item className={ styles.countryIcon }>
              <GiMexico/>
            </Grid>

            <Grid item xs container className={ styles.countryText }>
              <Typography component="span">Productos 100% mexicanos</Typography>
            </Grid>

          </Grid>


          <div className={ styles.list }>
            { footerNavigationLinks.map( ( { text, items }, i ) => (
                <List component="div" key={ i } className={ styles.footerNavSection }>
                  <ListSubheader component="div" className={ styles.subheader }>
                    <Typography variant="body2" className={ styles.sectionTitle }>
                      { text }
                    </Typography>
                  </ListSubheader>
                  <ul>
                    { items.map( ( { text, path, sectionID }, j ) => (
                        <ListItem key={ j } className={ styles.footerNavItem }>
                          { path ? (
                              <Typography
                                  onClick={ () => navigate( sectionID ? `${ path }/#${ sectionID }` : path ) }
                                  className={ styles.footerNavItemLink }
                              >
                                { text }
                              </Typography>
                          ) : (
                                <span className={ styles.footerNavItemLink }>{ text }</span>
                            ) }
                        </ListItem>
                    ) ) }
                  </ul>
                </List>
            ) ) }
          </div>


          <div className={ styles.links }>
            { externalLinks.map( ( item, index ) => {
              return (
                  <a
                      key={ index }
                      href={ item.path }
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    { item.text }
                  </a>
              )
            } ) }
            { internalLinks.map( ( item, index ) => {
              return (
                  <Link key={ index } to={ item.path }>
                    { item.text }
                  </Link>
              )
            } ) }
          </div>
          <div className={ styles.icons }>
            { socialLinks.map( ( item, index ) => {
              return (
                  <IconButton
                      key={ index }
                      aria-label={ item.title }
                      onClick={ () =>
                          typeof window !== 'undefined' && window.open( item.url )
                      }
                  >
                    { item.icon }
                  </IconButton>
              )
            } ) }
          </div>
          <div className={ styles.address }>
            La Guitarrita 61506 Heroica Zitácuaro, México
          </div>

          <Box my={ 4 }>
            <Typography variant="caption" className={ styles.developedBy }>
              Desarrollo por Caltec Soluciones SA de CV
            </Typography>
          </Box>
        </Box>
      </section>
  )
}

export default Footer
