// noinspection JSUnresolvedVariable

import React, { useCallback } from 'react'

import { FaWhatsapp } from 'react-icons/fa'
import { Grid } from '@material-ui/core'

import useStyles from './styles'
import { Button } from '../index'


const WhatsappContact = ( { number, message } ) => {
  const styles = useStyles()

  const getLinkWhastapp = useCallback( ( number, rawMessage ) => {
    if ( typeof window !== 'undefined' ) {
      return window.open(
          'https://api.whatsapp.com/send?phone=' +
          number +
          '&text=%20' +
          rawMessage.split( ' ' ).join( '%20' )
      )
    }
  }, [] )

  return (
      <Grid container className={ styles.buttonWrapper }>
        <Button handleClick={ () => getLinkWhastapp( number, message ) }>
          <Grid container justifyContent={ 'center' } alignItems={ 'center' }>
            <Grid item className={ styles.iconWrapper }>
              <FaWhatsapp/>
            </Grid>
            <Grid item xs>
              Escríbenos por WhatsApp
            </Grid>
          </Grid>
        </Button>
      </Grid>
  )
}

export default WhatsappContact
