import { useEffect, useState } from 'react'


const useWindowOnTop = ( { withNav } ) => {
  const [ isWindowOnTop, setIsWindowOnTop ] = useState( true )

  useEffect( () => {
    if ( typeof window !== 'undefined' ) {
      window.onscroll = () => {
        if ( window.pageYOffset <= 200  ) {
          setIsWindowOnTop( true )
        } else {
          setIsWindowOnTop( false )
        }
      }
    }
    return () => {
      setIsWindowOnTop( false )
    }
  }, [ withNav ] )

  return { isWindowOnTop }
}

export default useWindowOnTop