import { makeStyles } from '@material-ui/core'
import { MAX_WIDTH } from '../Layout/Layout'


export default makeStyles( ( theme ) => ({
  appBar: {},

  backdrop: {
    backgroundColor: 'transparent',
    backdropFilter: 'blur(3px)'
  },

  navbar: {
    backgroundColor: ( { isWindowOnTop } ) => isWindowOnTop ? 'transparent' : theme.palette.background.paper,
    position: 'absolute',
    height: ( { HEIGHT } ) => HEIGHT,
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: 'none',
    [theme.breakpoints.up( 'md' )]: {}
  },

  navbarMask: {
    width: '100%',
    height: '100%',
    position: 'absolute', top: 0, left: 0,
    background: ( { isWindowOnTop, isOpen } ) => isWindowOnTop || isOpen ? theme.palette.extras.none :
                                                 'rgba(255, 255, 255, 0.90)',

    backdropFilter: 'blur(3px)',
    opacity: ( { isWindowOnTop } ) => (isWindowOnTop ? 1 : 1),

    transition: theme.palette.extras.transitions.default
  },

  navLinks: {
    marginTop: theme.spacing( 2 ),
    padding: theme.spacing( 2 ),
    paddingTop: 0,
    paddingBottom: theme.spacing( 4 ),
    display: 'block',
    position: 'absolute',
    top: 0,
    left: theme.spacing( 2 ),
    right: theme.spacing( 2 ),
    zIndex: 9000,

    listStyleType: 'none',
    transition: theme.palette.extras.transitions.default,
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,

    borderRadius: theme.roundness,
    boxShadow: theme.shadows[12],
    '& a': {
      display: 'block',
      padding: theme.spacing( 1, 2 ),
      margin: theme.spacing( 1.5, 0 ),
      textDecoration: 'none',
      textTransform: 'none',
      fontSize: theme.typography.body2.fontSize,
      color: ( { isWindowOnTop } ) => isWindowOnTop
                                      ? theme.palette.extras.charcoal
                                      : theme.palette.text.primary,
      transition: theme.palette.extras.transitions.default,
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: theme.letterspacing( 0.5 )
    },

    [theme.breakpoints.up( 'md' )]: {
      display: 'flex',
      height: 'auto',
      position: 'static',
      justifyContent: 'space-around',
      padding: theme.spacing( 0, 4 ),
      margin: theme.spacing( 0 ),
      marginLeft: 'auto',
      borderRadius: 0,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      '& a': {
        fontSize: theme.typography.body2.fontSize
      },
      [theme.breakpoints.up( 'md' )]: {
        textAlign: 'center',
        '& :hover': {
          color: theme.palette.extras.charcoal,
          fontWeight: theme.typography.fontWeightBold
        }
      }
    }
  },

  animWrapper: {
    [theme.breakpoints.up( 'md' )]: {
      opacity: '1 !important', height: ( { HEIGHT } ) => HEIGHT
    }
  },

  selectedNavLink: {
    '& a': {
      width: '100%',
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.h6.fontSize
    },
    '& :hover': {
      color: theme.palette.extras.charcoal,
      fontWeight: theme.typography.fontWeightBold
    },
    [theme.breakpoints.up( 'md' )]: {
      fontWeight: theme.typography.fontWeightBold,
      borderRadius: theme.roundness,
      '& a': {
        fontSize: theme.typography.body1.fontSize,
        backgroundColor: theme.palette.primary.main,
        color: `${theme.palette.text.lightText} !important`,
        borderRadius: theme.roundness
      }
    }

  },
  logoBtnContainer: {
    alignItems: 'center',
    paddingLeft: theme.spacing( 2 ),
    marginTop: ( { isOpen } ) => isOpen ? theme.spacing( 3 ) : theme.spacing( 0 ),
    marginRight: ( { isOpen } ) => isOpen ? theme.spacing( 3 ) : theme.spacing( 0 ),
    [theme.breakpoints.up( 'md' )]: {
      display: 'none'
    }
  },
  logoBtn: {
    display: 'block',
    width: 44,
    height: 44,
    color:theme.palette.extras.charcoal,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    fontSize: theme.typography.h6.fontSize,
    zIndex: 9000
  },

  menuContainer: {
    opacity: ( { isWindowOnTop } ) => isWindowOnTop && 1,
    transition: theme.palette.extras.transitions.default,
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: MAX_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.breakpoints.up( 'md' )]: {
      display: 'block'
    }
  },

  hidden: {
    transition: theme.palette.extras.transitions.default, display: 'none', [theme.breakpoints.up( 'md' )]: {
      display: 'flex'
    }
  },

  shown: {
    padding: theme.spacing( 0 ),
    transition: theme.palette.extras.transitions.default,
    justifyContent: 'center',
    alignItems: 'center',
    opacity: ( { isWindowOnTop } ) => isWindowOnTop && 0,

    [theme.breakpoints.up( 'md' )]: {
      display: 'none'
    }
  },

  CTA: {
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.extras.charcoal,
    borderRadius: theme.roundnessSmall,
    '& a': {
      color: `${theme.palette.text.lightText} !important`
    },

    [theme.breakpoints.up( 'md' )]: {
      display: 'none'
    }
  },

  mdLogoContainer: {
    display: 'none',
    [theme.breakpoints.up( 'md' )]: {
      padding: theme.spacing( 0, 4 ),
      display: 'flex',
      alignItems: 'center'
    }
  }
}) )
