import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: ( props ) => (props.fullWidth ? '100%' : 'auto'),
    height: ( { small } ) => small ? theme.spacing( 4.5 ) : 'auto',

    padding: ( { discrete, small } ) =>
        discrete
        ? theme.spacing( 0 )
        : small
          ? theme.spacing( 0.5, 0.75 )
          : theme.spacing( 1, 3.5 ),

    background: ( { disabled } ) =>
        disabled ? theme.palette.text.disabled : theme.palette.primary.main,

    borderRadius: theme.roundnessSmall,

    fontSize: theme.typography.body2.fontSize,
    textTransform: 'none',
    textDecoration: 'none',
    color: theme.palette.text.lightText,
    lineHeight: theme.lineHeight( 1.75 ),
    letterSpacing: ( { small } ) =>
        small ? theme.letterspacing( 0.3 ) : theme.letterspacing( 0.75 ),
    transition: theme.palette.extras.transitions.default,
    border: ( { dark } ) => dark ? `1px ${ theme.palette.background.paper } solid` : 'none',

    '&:hover': {
      background: theme.palette.extras.charcoal,
      color: theme.palette.text.lightText,
      borderColor: theme.palette.text.lightText
    },

    [theme.breakpoints.up( 'md' )]: {
      fontSize: theme.typography.body1.fontSize
    }
  }
}) )
