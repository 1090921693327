import { useEffect, useState, useRef } from 'react'
import * as React from 'react'
import { useSpring, animated } from 'react-spring'

import { Button } from '@material-ui/core'
import Loader from './ButtonLoader'

import useStyles from './styles'
import { Link } from 'gatsby'


const ButtonComponent = props => {
  const {
    children,
    aria,
    handleClick,
    disabled,
    isLoading,
    goTo,
    ...rest
  } = props
  const classes = useStyles( { disabled: props.disabled, ...rest } )

  const [ showLoader, setShowLoader ] = React.useState( false )

  useEffect( () => {
    if ( isLoading ) {
      setShowLoader( true )
    }

    if ( !isLoading && showLoader ) {
      const timeout = setTimeout( () => {
        setShowLoader( false )
      }, 400 )

      return () => {
        clearTimeout( timeout )
      }
    }
  }, [ isLoading, showLoader ] )

  const [ width, setWidth ] = useState( 85 )
  const [ height, setHeight ] = useState( 32 )
  const ref = useRef( null )

  useEffect( () => {
    if ( ref.current && ref.current.getBoundingClientRect().width ) {
      setWidth( ref.current.getBoundingClientRect().width )
    }
    if ( ref.current && ref.current.getBoundingClientRect().height ) {
      setHeight( ref.current.getBoundingClientRect().height )
    }
  }, [] )

  const fadeOutProps = useSpring( { opacity: showLoader ? 1 : 0 } )
  const fadeInProps = useSpring( { opacity: showLoader ? 0 : 1 } )

  if ( goTo ) {
    return (
        <Link className={ classes.button } to={ goTo }>
          { children }
        </Link>
    )
  }

  return (
      <Button
          aria-label={ aria }
          ref={ ref }
          className={ classes.button }
          disabled={ isLoading || disabled }
          style={
            showLoader
            ? {
                  width: `${ width }px`,
                  height: `${ height }px`
                }
            : {}
          }
          onClick={ handleClick }
      >
        { showLoader ? (
            <animated.div style={ fadeOutProps }>
              <Loader/>
            </animated.div>
        ) : (
              <animated.div style={ fadeInProps }>{ children }</animated.div>
          ) }
      </Button>
  )
}

export default ButtonComponent
