import { makeStyles } from '@material-ui/core'
import { HEIGHT, MAX_WIDTH } from '../../_layout/Layout/Layout'


export default makeStyles( ( theme ) => ({
  heroRoot: {
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default
  },

  heroMainContentContainer: {
    marginTop: HEIGHT,
    height: '30vh',
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing( 4 ),
    [theme.breakpoints.up( 'md' )]: {
      height: '40vh',
      maxWidth: MAX_WIDTH,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },

  heroImageContainer: {
    height: '70vh',
    position: 'relative',
    left: '-10%',
    borderTopLeftRadius: '45%',
    borderTopRightRadius: '50%',
    width: '200%',
    overflow: 'hidden',
    transform: 'rotate(-2deg) skewY(10deg) translateY(100px)',
    [theme.breakpoints.up( 'md' )]: {
      height: '60vh'
    }
  },
  img: {
    height: '100%'
  },
  heroContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.overlays.gradientOverlay
  },
  heroText: {
    color: theme.palette.extras.charcoal,

    '& h1': {
      fontFamily: theme.typography.titleFontFamily,
      fontSize: theme.typography.h3.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: theme.letterspacing( 2 ),
      marginTop: theme.spacing( 0.5 )
    },
    '& h4': {
      fontSize: theme.typography.h5.fontSize
    },
    [theme.breakpoints.up( 'md' )]: {
      '& h1': {
        fontSize: theme.typography.h1.fontSize
      },
      '& h4': {
        fontSize: theme.typography.h4.fontSize
      }
    }
  },

  ctaWrapper: {
    width: 200,
    marginTop: theme.spacing( 2 )
  }
}) )

