import { Box, Typography } from '@material-ui/core'
import * as React from 'react'

import useStyles from './styles'


const SectionTitle = ( { title, centered, dark } ) => {
  const { root, title: titleClass } = useStyles( { centered, dark } )
  return (
      <Box className={ root }>
        <Typography className={ titleClass }>{ title }</Typography>
      </Box>
  )
}

export default SectionTitle
