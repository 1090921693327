import { useEffect, useState } from 'react'
// noinspection NpmUsedModulesInstalled
import { globalHistory } from '@reach/router'


const usePath = () => {
  const [ path, setPath ] = useState( globalHistory.location.pathname )

  useEffect( () => {
    setPath( globalHistory.location.pathname )
  }, [] )

  return [ path ]
}

export default usePath
